import $ from 'jquery';
import Anchor from '../Components/_Anchor.js';
import Swiper from '../Components/_Slider.js';

$(function () {
	// ハンバーガーメニュー
	$('.js-header-open').on('click', function () {
		$(this).toggleClass('is-open');
		$('.c-header__nav').toggleClass('is-open');
	});
	$('.c-header__link').on('click', function () {
		$('.c-header__nav').removeClass('is-open');
	});
});

$(function () {
	function updateMargin() {
		let height = $('.c-header').height();
		$('body').css('margin-top', height + 5);
	}

	function handleResize() {
		if ($(window).width() > 767) {
			updateMargin();
		} else {
			// 画面幅が767px以下の場合はマージンをリセット
			$('body').css('margin-top', '');
		}
	}

	// 初期設定
	handleResize();

	// ウィンドウサイズが変更されたときにもマージンを更新
	$(window).on('resize', function () {
		handleResize();
	});
});

