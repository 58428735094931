import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const slider1 = new Swiper('.c-slider1', {
	loop: true,
	speed: 600,
	autoplay: {
		delay: 3500,
		disableOnInteraction: false, // スライドに触っても自動再生を停止しない
	},
	effect: 'fade',
});

